
.aboutMe-content{
    justify-content: center;
}
.headerLabel {
    text-align: center;
    font-size: 2rem;
    padding: 0.6rem 0 1.5rem;
}

.description{
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0.6rem 0 1.5rem;
}