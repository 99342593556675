.project-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
    color: white;
  }
  
  .project-container {
    max-width: 1140px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding-bottom: 5rem;
    justify-content: center;
  }
  
  .project-card {
    background-color: #1a1919;
    border-radius: 5px;
    padding: 1.2rem 1rem;
    flex: 1 1 300px; 
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
  }
  
.project-card img{
    border-radius: 5px;
    width:auto;
    height: 100%;
    max-height: 200px; 

    align-self: center;
}


  .project-title {
    text-align: center;
    color: white;
    font-size: 2rem;
    text-decoration: underline;
  }
  .project-stack {
    text-align: center;
    color: white;
    font-size: 1.5rem;
  }
  .pro-details {
    flex: 1; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .pro-details p {
    margin: 0; 
    padding: 1rem;
    font-size: 1.1rem;
    text-align: justify;
    word-break: break-word; /* Break long words to avoid overflow */
    hyphens: auto; /* Add hyphens where appropriate */
  }
  
  .details {
    display: flex;
    align-items: flex-end; /* Align button to the bottom */
    padding-top: 1rem; /* Adjust padding to position button correctly */
    flex: 1; /* Make sure .details takes up remaining space */
  }
  
  .details-btn {
    width: 100%; /* Ensure button takes full width */
    padding: 0.5rem 1rem;
    margin:5px;
    background: rgb(248, 217, 15);
    border-radius: 5px;
    color: #222;
    border: 1px solid #fff;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none; /* Ensure the link looks like a button */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (max-width: 740px) {
    .education-container {
      max-width: 90%;
      margin: auto;
      flex-direction: column; /* Stack cards vertically on small screens */
    }
    
    .pro-details p {
      font-size: 1rem; /* Adjust font size for better readability on small screens */
    }
  }