.page-mask {
    width: 100%;
    height: 60vh;
    position: relative;
    overflow: hidden; 
}

.page-img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: transparent;
}

.content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
    z-index: 3;
    color: rgb(255, 255, 255);
}

.ExperienceLabel {
    color: rgb(255, 0, 106);
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.ProjectLabel {
    color: rgb(255, 145, 0);
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}


.ContactLabel{
    color: rgb(253, 255, 109);
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.PageDescription{
    font-size: 2rem;
    font-weight: 500;
    padding: 0.6rem 0 1.5rem;
}