.mask {
    width: 100%;
    height: 100vh;
    position: relative;
}

.into-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute; /* Make sure the image is positioned absolutely */
    top: 0;
    left: 0;
    z-index: 1; /* Ensure the image is below the content */
}

.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.8;
    z-index: 2; /* Ensure the overlay is above the image */
}

.homeImg {
    height: 100%;
    width: 100%;
    position: relative;
}

.homeImg .content {
    position: absolute; /* Position the content absolutely */
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
    z-index: 3; /* Ensure the content is above the overlay */
    color: white; /* Ensure the text is visible */
}
.homeImg .content h1 {
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.homeImg .content p {
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
}
.profile-img {
    width: 250px; 
    height: 250px; 
    border-radius: 50%; 
    border: 3px solid white; 
  }
.content .btn {
    margin: 1rem 0.2rem;
}

@media screen and (max-width: 640px) {
    .homeImg .content h1 {
        font-size: 3rem;
    }

    .homeImg .content p {
        font-size: 1.4rem;
    }

.profile-img {
    width: 50vw; 
    height: 50vw; 
  }


}

