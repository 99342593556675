.skills-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the container content */
    text-align: center;
    padding: 20px;
}

.skills-container h1 {
    margin-bottom: 20px;
}

.skills-container span {
    display: flex;
    align-items: center;
    gap: 5px;
}

.skills-container img {
    width: 20px;
}

.skillList {
    display: flex;
    justify-content: center; /* Center the skills list horizontally */
    flex-wrap: wrap;
    gap: 20px; /* Add some gap between items */
    max-width: 600px; /* Set a max-width for the container */
    width: 100%;
}

hr {
    width: 100px;
    height: 1px;
    border: none;
    background-color: #fff; /* Ensure the color is white */
    margin: 30px auto;
}